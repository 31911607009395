import { ComponentInternalInstance, onMounted, ref, toRef, watch } from '@vue/composition-api'

import { ComponentObjectPropsOptions } from '../../vue-api'

import { TabsNavProps, UseTabsNavProvides } from './TabsNav.contracts'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export const tabsNavProps: ComponentObjectPropsOptions<TabsNavProps> = {
  activeContainerId: {
    type: String,
    required: true
  },
  containerPrefix: {
    type: String,
    required: false,
    default: 'c_'
  },
  tabs: {
    type: Array,
    required: true
  },
  withoutRole: {
    type: Boolean,
    required: false,
    default: false
  }
}

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export const useTabsNav = (
  props: TabsNavProps,
  internalInstance: ComponentInternalInstance | null
): UseTabsNavProvides => {
  if (!internalInstance) {
    throw new Error('useSearchInput(): Instance for component is not found!')
  }

  const _activeContainerId = ref<string | null>(props.activeContainerId)
  const activeContainerId = toRef(props, 'activeContainerId')

  let tablist: HTMLDivElement

  /**
   * Handles the `@click` event on the tab button.
   *
   * @param containerId - The ID of the container associated with the clicked tab.
   * @param useAnchor
   */
  const onBtnClick = (containerId: string, useAnchor: boolean): void => {
    if (useAnchor) {
      const body = document.querySelector('body')
      const ref = document.getElementById(`${props.containerPrefix}${containerId}`)
      const bodyMargin = window.document.documentElement.style.getPropertyValue('--body-margin-top')
      const offset = bodyMargin ? parseInt(bodyMargin) : 0

      if (ref && body) {
        window.scrollTo({
          left: 0,
          top: ref.getBoundingClientRect().top - body.getBoundingClientRect().top - offset,
          behavior: 'smooth'
        })
      }
    } else {
      internalInstance.emit('update:activeContainerId', containerId)
      _activeContainerId.value = containerId
    }
  }

  /**
   * Get aria selected value for given container.
   */
  const getAriaSelected = (containerId: string): string => {
    return isContainerActive(containerId) ? 'true' : 'false'
  }

  /**
   * Get role value.
   */
  const getRole = (role: string): string | null => {
    return props.withoutRole ? null : role
  }

  /**
   * Get tab index for given container.
   */
  const getTabIndex = (containerId: string): string | null => {
    if (props.withoutRole) {
      return null
    }

    return isContainerActive(containerId) ? '0' : '-1'
  }

  /**
   * Determines is active container.
   */
  const isContainerActive = (containerId: string): boolean => {
    return containerId === _activeContainerId.value
  }

  /**
   * Enables switching between tabs wtth arrows in keyboard.
   */
  const enableTablistKeyboardControls = (): void => {
    tablist = (internalInstance.refs.tablist) as HTMLDivElement

    if (typeof tablist === 'undefined') {
      return
    }

    const tabsBtns = tablist.querySelectorAll<HTMLButtonElement>('.Tablist__item__btn')

    // Enable arrow navigation between tabs
    let tabFocus: number = 0

    tablist.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.keyCode === 39 || e.keyCode === 37) {
        // Move right
        if (e.keyCode === 39) {
          tabFocus++

          // If we are at the end, jump to start.
          if (tabFocus >= tabsBtns.length) {
            tabFocus = 0
          }
        }

        // Move left
        if (e.keyCode === 37) {
          tabFocus--

          // If we are at the start, jumo to end
          if (tabFocus < 0) {
            tabFocus = tabsBtns.length - 1
          }
        }

        tabsBtns[tabFocus].focus()
      }
    })
  }

  onMounted(() => {
    enableTablistKeyboardControls()
  })

  watch(activeContainerId, () => {
    _activeContainerId.value = activeContainerId.value
  })

  return {
    _activeContainerId,
    getAriaSelected,
    getRole,
    getTabIndex,
    isContainerActive,
    onBtnClick
  }
}
