






import { defineComponent, getCurrentInstance } from '@vue/composition-api'

import { TabsNav } from '../../atoms/TabsNav'

import { tabsProps, useTabs } from './Tabs.hooks'
import { TabsProps } from './Tabs.contracts'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export const Tabs = defineComponent({
  name: 'Tabs',
  props: tabsProps,
  components: { TabsNav },

  setup (props: TabsProps) {
    return useTabs(props, getCurrentInstance())
  }
})
export default Tabs
