import { ComponentInternalInstance, computed, onMounted, ref, toRef } from '@vue/composition-api'

import { ComponentObjectPropsOptions } from '../../vue-api'

import { TabsProps, UseTabsProvides } from './Tabs.contracts'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export const tabsProps: ComponentObjectPropsOptions<TabsProps> = {
  containers: {
    type: Object,
    required: true
  },
  tabs: {
    type: Array,
    required: true
  }
}

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export const useTabs = (
  props: TabsProps,
  internalInstance: ComponentInternalInstance | null
): UseTabsProvides => {
  if (!internalInstance) {
    throw new Error('useSearchInput(): Instance for component is not found!')
  }

  /**
   * ID of the currently-active (visible) container.
   */
  const mActiveContainerId = ref<string>(props.activeContainerId ?? '')

  /**
   * Containers not including 'useAnchor' property
   */
  const nonAnchorContainers = ref<{ [k: string]: NodeList } | null>(null)

  const activeContainerId = computed({
    get: () => mActiveContainerId.value,
    set: (containerId: string) => {
      if (containerId === mActiveContainerId.value) {
        return
      }

      hideAllContainers()
      showContainerById(containerId)
      mActiveContainerId.value = containerId

      internalInstance.emit('update:activeContainerId', containerId)
    }
  })

  /**
   * Activates the container associated with the 1st tab in the set.
   */
  const activateFirstContainer = (): void => {
    activeContainerId.value = props.tabs[0].containerId
  }

  const declareNonAnchorContainers = (): void => {
    const anchorTabs = props.tabs.filter((tab) => tab.useAnchor)
    const anchorContainers: string[] = []
    for (const tab of anchorTabs) {
      anchorContainers.push(tab.containerId)
    }

    nonAnchorContainers.value = Object.fromEntries(Object.entries(props.containers)
      .filter(([key]) => !anchorContainers.includes(key)))
  }

  /**
   * Hides (conceals) all containers.
   */
  const hideAllContainers = (): void => {
    if (nonAnchorContainers.value) {
      Object.values(nonAnchorContainers.value).forEach(container => {
        container.forEach((child) => {
          hideContainer(child as HTMLDivElement)
        })
      })
    } else {
      Object.values(props.containers).forEach(container => {
        container.forEach((child) => {
          hideContainer(child as HTMLDivElement)
        })
      })
    }
  }

  /**
   * Hides (conceals) the passed-in container HTML element.
   *
   * @param container - The container element to hide (conceal).
   */
  const hideContainer = (container: HTMLDivElement): void => {
    container.style.display = 'none'
  }

  /**
   * Shows (reveals) the passed-in container HTML element.
   *
   * @param container - The container element to show (reveal).
   */
  const showContainer = (container: HTMLDivElement): void => {
    container.setAttribute('tabindex', '0')
    container.style.display = ''
  }

  /**
   * Shows (reveals) the container of a passed-in ID.
   *
   * @param containerId - ID of the container to show (reveal).
   */
  const showContainerById = (containerId: string): void => {
    const containers = props.containers[containerId]
    if (containers.length === 0) {
      return
    }

    containers.forEach((container) => {
      showContainer(container as HTMLDivElement)
    })
  }

  onMounted(() => {
    declareNonAnchorContainers()

    if (Object.keys(props.containers).length > 0) {
      if (nonAnchorContainers.value) {
        Object.values(nonAnchorContainers.value).forEach((container) => {
          [...container].forEach((element) => {
            (element as HTMLDivElement).setAttribute('role', 'tabpanel')
          })
        })
      } else {
        Object.values(props.containers).forEach((container) => {
          [...container].forEach((element) => {
            (element as HTMLDivElement).setAttribute('role', 'tabpanel')
          })
        })
      }
    }

    activateFirstContainer()
  })

  return {
    activeContainerId
  }
}
