
























import { defineComponent, getCurrentInstance } from '@vue/composition-api'

import { tabsNavProps, useTabsNav } from './TabsNav.hooks'
import { TabsNavProps } from './TabsNav.contracts'
/**
 * @emits update:activeContainerId - When the User clicks any of the tab buttons.
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export const TabsNav = defineComponent({
  name: 'TabsNav',
  props: tabsNavProps,

  setup (props: TabsNavProps) {
    return useTabsNav(props, getCurrentInstance())
  }
})
export default TabsNav
